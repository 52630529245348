/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DiagramType } from './diagramType';


/**
 * Project diagram (new)
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectDiagramNew { 
    diagramType?: DiagramType;
    /**
     * Factor identifier
     */
    identifier?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    name?: string;

    constructor(item?: Partial<ProjectDiagramNew>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            diagramType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.diagramType : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            identifier: {
                
                minLength: 0,
                maxLength: 255,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 0, 255);

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProjectDiagramNew.isValid,
        };
    }

    static isValid(obj: ProjectDiagramNew): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectDiagramNew.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.diagramType.isValid(item);
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

