/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IndicatorScorecardAggregationMethodEnum } from './indicatorScorecardAggregationMethodEnum';
import { IndicatorScorecardMeasurementCategoryEnum } from './indicatorScorecardMeasurementCategoryEnum';
import { TaxonomyClassificationNew } from './taxonomyClassificationNew';


/**
 * Indicator Scorecard
 */
import { ValidateUtils } from 'app/shared/utils';

export class IndicatorScorecardNew { 
    /**
     * Scorecard name
     */
    name?: string;
    /**
     * Scorecard description
     */
    description?: string;
    rollupTaxonomyClassifications?: TaxonomyClassificationNew;
    /**
     * Unique identifier for Indicator primary aggregation Taxonomy Version
     */
    primaryAggregationTaxonomyVersionResourceIdentifier?: string;
    /**
     * Unique identifier for Indicator secondary aggregation Taxonomy Version
     */
    secondaryAggregationTaxonomyVersionResourceIdentifier?: string;
    aggregationMethod?: IndicatorScorecardAggregationMethodEnum;
    measurementCategory?: IndicatorScorecardMeasurementCategoryEnum;

    constructor(item?: Partial<IndicatorScorecardNew>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            description: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.description : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            rollupTaxonomyClassifications: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.rollupTaxonomyClassifications : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            primaryAggregationTaxonomyVersionResourceIdentifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.primaryAggregationTaxonomyVersionResourceIdentifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            secondaryAggregationTaxonomyVersionResourceIdentifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.secondaryAggregationTaxonomyVersionResourceIdentifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            aggregationMethod: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.aggregationMethod : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            measurementCategory: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.measurementCategory : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: IndicatorScorecardNew.isValid,
        };
    }

    static isValid(obj: IndicatorScorecardNew): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = IndicatorScorecardNew.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.description.isValid(item);
            result = result && validationObject.rollupTaxonomyClassifications.isValid(item);
            result = result && validationObject.primaryAggregationTaxonomyVersionResourceIdentifier.isValid(item);
            result = result && validationObject.secondaryAggregationTaxonomyVersionResourceIdentifier.isValid(item);
            result = result && validationObject.aggregationMethod.isValid(item);
            result = result && validationObject.measurementCategory.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

