/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Program / Project associated file
 */
import { ValidateUtils } from 'app/shared/utils';

export class AssociatedFile { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    /**
     * Url for download of file
     */
    readonly fileDownloadUrl?: string;
    /**
     * File name
     */
    readonly fileName?: string;
    /**
     * File size
     */
    readonly fileSize?: string;
    /**
     * File uploaded date
     */
    readonly uploadedDate?: string;
    /**
     * File content type
     */
    readonly contentType?: string;

    constructor(item?: Partial<AssociatedFile>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            fileDownloadUrl: {
                type: 'uri',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.fileDownloadUrl : itemOrValue;

                    if (itemOrValue?.fileDownloadUrl != null) {
                        if (itemOrValue.fileDownloadUrl === '') {
                            itemOrValue.fileDownloadUrl = undefined;
                        } else if (['int32', 'int64'].indexOf('uri') >= 0) {
                            itemOrValue.fileDownloadUrl = parseInt(itemOrValue.fileDownloadUrl.toString(), 10);
                        } else if (['float'].indexOf('uri') >= 0) {
                            itemOrValue.fileDownloadUrl = parseFloat(itemOrValue.fileDownloadUrl.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uri');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            fileName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.fileName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            fileSize: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.fileSize : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            uploadedDate: {
                type: 'date-time',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.uploadedDate : itemOrValue;

                    if (itemOrValue?.uploadedDate != null) {
                        if (itemOrValue.uploadedDate === '') {
                            itemOrValue.uploadedDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date-time') >= 0) {
                            itemOrValue.uploadedDate = parseInt(itemOrValue.uploadedDate.toString(), 10);
                        } else if (['float'].indexOf('date-time') >= 0) {
                            itemOrValue.uploadedDate = parseFloat(itemOrValue.uploadedDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date-time');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            contentType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.contentType : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: AssociatedFile.isValid,
        };
    }

    static isValid(obj: AssociatedFile): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = AssociatedFile.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.fileDownloadUrl.isValid(item);
            result = result && validationObject.fileName.isValid(item);
            result = result && validationObject.fileSize.isValid(item);
            result = result && validationObject.uploadedDate.isValid(item);
            result = result && validationObject.contentType.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

