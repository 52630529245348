/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Edit status for Project (file)
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectEditStatus { 
    /**
     * Unique identifier
     */
    readonly identifier?: string;
    /**
     * Project name
     */
    readonly name?: string;
    /**
     * Project edit event datetime
     */
    readonly projectEditEventDatetime?: string;

    constructor(item?: Partial<ProjectEditStatus>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            identifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            projectEditEventDatetime: {
                type: 'date-time',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectEditEventDatetime : itemOrValue;

                    if (itemOrValue?.projectEditEventDatetime != null) {
                        if (itemOrValue.projectEditEventDatetime === '') {
                            itemOrValue.projectEditEventDatetime = undefined;
                        } else if (['int32', 'int64'].indexOf('date-time') >= 0) {
                            itemOrValue.projectEditEventDatetime = parseInt(itemOrValue.projectEditEventDatetime.toString(), 10);
                        } else if (['float'].indexOf('date-time') >= 0) {
                            itemOrValue.projectEditEventDatetime = parseFloat(itemOrValue.projectEditEventDatetime.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date-time');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProjectEditStatus.isValid,
        };
    }

    static isValid(obj: ProjectEditStatus): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectEditStatus.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.projectEditEventDatetime.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

