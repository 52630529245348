/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ViabilityRatingEnum } from './viabilityRatingEnum';
import { IndicatorFutureStatusAllOf } from './indicatorFutureStatusAllOf';
import { IndicatorFutureStatusNew } from './indicatorFutureStatusNew';


/**
 * Indicator future status
 */
import { ValidateUtils } from 'app/shared/utils';

export class IndicatorFutureStatus { 
    /**
     * Future Status name
     */
    name?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    details?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    comments?: string;
    /**
     * Status date
     */
    statusDate?: string;
    /**
     * Future Status summary
     */
    summary?: string;
    rating?: ViabilityRatingEnum;
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier: string;

    constructor(item?: Partial<IndicatorFutureStatus>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            details: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.details : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            comments: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.comments : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            statusDate: {
                type: 'date',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.statusDate : itemOrValue;

                    if (itemOrValue?.statusDate != null) {
                        if (itemOrValue.statusDate === '') {
                            itemOrValue.statusDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date') >= 0) {
                            itemOrValue.statusDate = parseInt(itemOrValue.statusDate.toString(), 10);
                        } else if (['float'].indexOf('date') >= 0) {
                            itemOrValue.statusDate = parseFloat(itemOrValue.statusDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            summary: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.summary : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            rating: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.rating : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            resourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: IndicatorFutureStatus.isValid,
        };
    }

    static isValid(obj: IndicatorFutureStatus): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = IndicatorFutureStatus.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.details.isValid(item);
            result = result && validationObject.comments.isValid(item);
            result = result && validationObject.statusDate.isValid(item);
            result = result && validationObject.summary.isValid(item);
            result = result && validationObject.rating.isValid(item);
            result = result && validationObject.resourceIdentifier.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

