import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Observer, ReplaySubject, throwError } from 'rxjs';
import { catchError, delay, map, mergeMap } from 'rxjs/operators';
import { ProjectChangeMessage } from '../models';
import { AppService, GlobalBusyService, NotificationService, Project, ProjectEditStatus, ProjectsService, ThreatReductionResult } from '../services';
import { AuthService } from '../services/auth/auth.service';
import { CaseUtils } from '../utils';


interface RequestItem {
  requestHandler: Observable<HttpEvent<any>>;
  observer: Observer<HttpEvent<any>>;
}

@Injectable()
export class MiradiHttpInterceptor implements HttpInterceptor {

  private readonly diagramRelatedApiEndpoints = [
    '/projectdiagramfactors',
    '/projectdiagramlinks',
    '/projectdiagramtags',
    '/groupboxes',
    '/scopeboxes',
    '/textboxes',
  ];
  private readonly projectEditStatusApiEndpoint = '/projecteditstatus';

  private pendingObservers: Observer<void>[];
  private project: Project;
  private requestItemsQueue: RequestItem[] = [];

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private globalBusyService: GlobalBusyService,
    private notificationService: NotificationService,
    private projectsService: ProjectsService,
    private router: Router,
    private translateService: TranslateService,
  ) {
    this.appService.listenToProjectChangeMessage()
    .subscribe((pcm: ProjectChangeMessage) => {
      this.project = pcm?.project;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler, replaceItemInQueueAndRetry?: boolean): Observable<HttpEvent<any>> {
    if (
      ['POST', 'PUT', 'DELETE'].indexOf(request.method) >= 0 ||
      (request.url.indexOf(this.projectEditStatusApiEndpoint) >= 0 && request.method === 'GET' && !request.headers.has('MS-Bypass-Http-Queue'))
    ) {
      return new Observable((observer: Observer<any>) => {
        if (replaceItemInQueueAndRetry && this.requestItemsQueue.length) {
          this.requestItemsQueue[0] = {
            requestHandler: this.handleRequest(request, next),
            observer: observer,
          };
        } else {
          this.requestItemsQueue.push({
            requestHandler: this.handleRequest(request, next),
            observer: observer,
          });
        }

        if (this.requestItemsQueue.length === 1 || replaceItemInQueueAndRetry) {
          this.dispatchRequest();
        }
      });
    } else {
      return this.handleRequest(request, next);
    }
  }

  private dispatchRequest(): void {
    if (this.requestItemsQueue.length <= 0) return;

    const requestItem = this.requestItemsQueue[0];
    requestItem.requestHandler
    .subscribe((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        requestItem.observer.next(event);
        requestItem.observer.complete();

        this.processNextRequest();
      }
    }, (error: any) => {
      requestItem.observer.error(error);

      this.processNextRequest();
    });
  }

  private processNextRequest(): void {
    if (this.requestItemsQueue?.length > 0) {
      this.requestItemsQueue.shift();
    }
    this.dispatchRequest();
  }

  private handleRequest(request: HttpRequest<any>, next: HttpHandler, observer?: Observer<any>): Observable<HttpEvent<any>> {
    return next.handle(request)
    .pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.headers.has('MS-Project-Edit-Event-Datetime')) {
            this.appService.projectEditEventDatetime = event.headers.get('MS-Project-Edit-Event-Datetime');
          }
        }

        if (observer) {
          observer.next(event);
          observer.complete();
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0) {
          return this.handleNetworkError()
          .pipe(
            delay(500),
            mergeMap(() => {
              return this.intercept(request.clone(), next, true);
            })
          );
        } else if (request.url.indexOf('/user') >= 0) {
          return throwError(error);
        } else if (request.headers.has('MS-IgnoreErrors')) {
          return throwError(error);
        } else if (error.status === 401) {
          setTimeout(() => {
            this.authService.discovery();
          }, 2000);

          return throwError(error);
        } else if (error.status === 403) {
          setTimeout(() => {
            this.router.navigate(['/home-user']);
          }, 2000);

          return throwError(error);
        } else if (error.status === 503) {
          setTimeout(() => {
            window.location.href = window.location.href;
          }, 2000);

          return throwError(error);
        }

        if (error.error) {
          error = this.camelcase(error.error);
        } else {
          error = this.camelcase(error);
        }

        if (
          !this.diagramRelatedApiEndpoints.some((apiEndpoint: string) => {
            return request.url.indexOf(apiEndpoint) >= 0;
          })
        ) {
          // if we are here, there's been an error not related to any diagram calls
          this.notificationService.apiError(error);
          return throwError(error);
        } else if (error?.status === 500) {
          // if we are here, there's been an error in a diagramRelatedApiEndpoint
          this.notificationService.clear();

          this.notificationService.apiError(error, 0, false);

          this.appService.emitProjectChangeMessage({ project: this.project, source: 'diagram-invalidate-undo', options: { diagramColorPaletteChanged: false, invalidateDiagramUndo: true, } }, false)

          return throwError(error);
        }

        this.projectsService.defaultHeaders = this.projectsService.defaultHeaders.set('MS-Bypass-Http-Queue', 'true');
        return this.projectsService.getProjectEditStatus(this.project.identifier)
        .pipe(
          mergeMap((pes: ProjectEditStatus) => {
            this.projectsService.defaultHeaders.delete('MS-Bypass-Http-Queue');

            if (
              this.appService.projectEditEventDatetime &&
              this.appService.projectEditEventDatetime !== pes.projectEditEventDatetime
            ) {
              if ((window as any).appInsights) {
                (window as any).appInsights.trackTrace({
                  message: 'ProjectRefreshed - After Client Error Check',
                  severityLevel: SeverityLevel.Information,
                });
              }

              this.notificationService.clear();

              this.notificationService.info(
                this.translateService.instant('Project has been updated'),
                this.translateService.instant('Refresh for current version'),
                0,
                { isProjectEditStatus: true }
              );

              this.notificationService.apiError(error, 0, false);

              this.notificationService.warn(
                this.translateService.instant('Undo Stack'),
                this.translateService.instant('Due to the above error, and the fact that the project has been updated, the diagram undo stack was cleared.'),
                0
              );

              this.appService.emitProjectChangeMessage({ project: this.project, source: 'diagram-invalidate-undo', options: { diagramColorPaletteChanged: false, invalidateDiagramUndo: true, } }, false);
            } else {
              this.notificationService.apiError(error);
            }

            return throwError(error);
          })
        );
      })
    );
  }


  private handleNetworkError(): Observable<void> {
    this.globalBusyService.setBusy(false);
    return new Observable((observer: Observer<void>) => {
      this.pendingObservers = this.pendingObservers || [];
      this.pendingObservers.push(observer);

      if ((window as any).appInsights) {
        (window as any).appInsights.trackException({
          exception: new Error('Network Error - Failed to reach the server.'),
        });
      }

      this.appService.globalInfoDialog.show({
        title: this.translateService.instant('Network Error'),
        message: this.translateService.instant('Failed to reach the server.'),
        buttonText: this.translateService.instant('Retry'),
      });
      this.appService.globalInfoDialog.onClose = () => {
        for (const obs of this.pendingObservers || []) {
          obs.next();
          obs.complete();
        }
      };
    });
  }

  private camelcase(obj: any): any {
    if (typeof obj === 'string') {
        return obj;
    } else {
        return CaseUtils.toCamel(obj);
    }
  }
}
