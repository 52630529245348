/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FactorObjectiveProgress } from './factorObjectiveProgress';


/**
 * Project factor objective progress
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectFactorObjectiveProgress { 
    /**
     * Unique identifier for Project
     */
    readonly projectIdentifier?: string;
    /**
     * Project name
     */
    readonly projectName?: string;
    /**
     * Factors for Project
     */
    factors?: Array<FactorObjectiveProgress>;

    constructor(item?: Partial<ProjectFactorObjectiveProgress>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            projectIdentifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectIdentifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            projectName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            factors: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factors : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProjectFactorObjectiveProgress.isValid,
        };
    }

    static isValid(obj: ProjectFactorObjectiveProgress): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectFactorObjectiveProgress.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.projectIdentifier.isValid(item);
            result = result && validationObject.projectName.isValid(item);
            result = result && validationObject.factors.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

