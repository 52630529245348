/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NotificationIntervalEnum } from './notificationIntervalEnum';


/**
 * Project check-out info
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectCheckoutInfo { 
    /**
     * Check-out message
     */
    checkoutMessage?: string;
    notificationInterval?: NotificationIntervalEnum;

    constructor(item?: Partial<ProjectCheckoutInfo>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            checkoutMessage: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.checkoutMessage : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            notificationInterval: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.notificationInterval : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProjectCheckoutInfo.isValid,
        };
    }

    static isValid(obj: ProjectCheckoutInfo): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectCheckoutInfo.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.checkoutMessage.isValid(item);
            result = result && validationObject.notificationInterval.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

