/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Project scope human stakeholders
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectScopeHumanStakeholders { 
    /**
     * Scope human population (size)
     */
    humanPopulation?: number | null;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    humanPopulationNotes?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    socialContext?: string;

    constructor(item?: Partial<ProjectScopeHumanStakeholders>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            humanPopulation: {
                type: 'int32',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.humanPopulation : itemOrValue;

                    if (itemOrValue?.humanPopulation != null) {
                        if (itemOrValue.humanPopulation === '') {
                            itemOrValue.humanPopulation = undefined;
                        } else if (['int32', 'int64'].indexOf('int32') >= 0) {
                            itemOrValue.humanPopulation = parseInt(itemOrValue.humanPopulation.toString(), 10);
                        } else if (['float'].indexOf('int32') >= 0) {
                            itemOrValue.humanPopulation = parseFloat(itemOrValue.humanPopulation.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'int32');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            humanPopulationNotes: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.humanPopulationNotes : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            socialContext: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.socialContext : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProjectScopeHumanStakeholders.isValid,
        };
    }

    static isValid(obj: ProjectScopeHumanStakeholders): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectScopeHumanStakeholders.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.humanPopulation.isValid(item);
            result = result && validationObject.humanPopulationNotes.isValid(item);
            result = result && validationObject.socialContext.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

