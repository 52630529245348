/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReportType } from './reportType';


/**
 * Program custom report (new / update)
 */
import { ValidateUtils } from 'app/shared/utils';

export class CustomReportNew { 
    reportType?: ReportType;
    /**
     * Custom report name
     */
    name?: string;
    /**
     * Custom report description
     */
    description?: string;
    /**
     * Custom report guidance
     */
    guidance?: string;
    /**
     * List of tags for filtering (from list of custom reports)
     */
    filterTags?: Array<string>;
    /**
     * Client-side json used to manage report column definitions
     */
    clientColumnConfig?: Array<object>;
    /**
     * Client-side json used to manage report filters
     */
    clientFilter?: object;

    constructor(item?: Partial<CustomReportNew>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            reportType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.reportType : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            name: {
                
                minLength: 1,
                maxLength: 200,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 1, 200);

                    return result;
                }
            },
            description: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.description : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            guidance: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.guidance : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            filterTags: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.filterTags : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            clientColumnConfig: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.clientColumnConfig : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            clientFilter: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.clientFilter : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: CustomReportNew.isValid,
        };
    }

    static isValid(obj: CustomReportNew): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = CustomReportNew.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.reportType.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.description.isValid(item);
            result = result && validationObject.guidance.isValid(item);
            result = result && validationObject.filterTags.isValid(item);
            result = result && validationObject.clientColumnConfig.isValid(item);
            result = result && validationObject.clientFilter.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

