/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MiradiSubscription } from './miradiSubscription';


/**
 * Miradi Share user info (for those with projects)
 */
import { ValidateUtils } from 'app/shared/utils';

export class UserInfo { 
    /**
     * Unique identifier
     */
    readonly identifier?: string;
    /**
     * First name
     */
    readonly firstName?: string;
    /**
     * Last name
     */
    readonly lastName?: string;
    /**
     * Organization identifier
     */
    readonly organizationIdentifier?: string;
    /**
     * Organization name
     */
    readonly organizationName?: string;
    /**
     * Email address
     */
    readonly email?: string;
    /**
     * Username
     */
    readonly username?: string;
    /**
     * Timezone
     */
    readonly timezone?: string;
    /**
     * Date user account created
     */
    joinedDate?: string | null;
    /**
     * Date of last login for user
     */
    readonly lastLoginDate?: string;
    miradiSubscription?: MiradiSubscription;
    /**
     * Flag indicator whether user account has API access enabled
     */
    readonly apiAccessEnabled?: boolean;
    /**
     * Flag indicating whether User is beta tester
     */
    readonly isBetaTester?: boolean;
    /**
     * Comma-delimited list of programs that user is member of
     */
    readonly programs?: string;
    /**
     * Total project count (where team member)
     */
    readonly totalProjectCount?: number;
    /**
     * Total standalone project count (where team member)
     */
    readonly standaloneProjectCount?: number;
    /**
     * Flag indicating whether User has an active associated subscription, directly or inherited by program / project membership
     */
    readonly activeAssociatedSubscription?: boolean;
    /**
     * List of associated subscriptions, directly or inherited by program / project membership
     */
    readonly associatedSubscriptions?: string;

    constructor(item?: Partial<UserInfo>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            identifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    if (itemOrValue?.identifier != null) {
                        if (itemOrValue.identifier === '') {
                            itemOrValue.identifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.identifier = parseInt(itemOrValue.identifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.identifier = parseFloat(itemOrValue.identifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            firstName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.firstName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            lastName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            organizationIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.organizationIdentifier : itemOrValue;

                    if (itemOrValue?.organizationIdentifier != null) {
                        if (itemOrValue.organizationIdentifier === '') {
                            itemOrValue.organizationIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.organizationIdentifier = parseInt(itemOrValue.organizationIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.organizationIdentifier = parseFloat(itemOrValue.organizationIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            organizationName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.organizationName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            email: {
                type: 'email',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.email : itemOrValue;

                    if (itemOrValue?.email != null) {
                        if (itemOrValue.email === '') {
                            itemOrValue.email = undefined;
                        } else if (['int32', 'int64'].indexOf('email') >= 0) {
                            itemOrValue.email = parseInt(itemOrValue.email.toString(), 10);
                        } else if (['float'].indexOf('email') >= 0) {
                            itemOrValue.email = parseFloat(itemOrValue.email.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'email');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            username: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.username : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            timezone: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.timezone : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            joinedDate: {
                type: 'date',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.joinedDate : itemOrValue;

                    if (itemOrValue?.joinedDate != null) {
                        if (itemOrValue.joinedDate === '') {
                            itemOrValue.joinedDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date') >= 0) {
                            itemOrValue.joinedDate = parseInt(itemOrValue.joinedDate.toString(), 10);
                        } else if (['float'].indexOf('date') >= 0) {
                            itemOrValue.joinedDate = parseFloat(itemOrValue.joinedDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            lastLoginDate: {
                type: 'date-time',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastLoginDate : itemOrValue;

                    if (itemOrValue?.lastLoginDate != null) {
                        if (itemOrValue.lastLoginDate === '') {
                            itemOrValue.lastLoginDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date-time') >= 0) {
                            itemOrValue.lastLoginDate = parseInt(itemOrValue.lastLoginDate.toString(), 10);
                        } else if (['float'].indexOf('date-time') >= 0) {
                            itemOrValue.lastLoginDate = parseFloat(itemOrValue.lastLoginDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date-time');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            miradiSubscription: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.miradiSubscription : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            apiAccessEnabled: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.apiAccessEnabled : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isBetaTester: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isBetaTester : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            programs: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.programs : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            totalProjectCount: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.totalProjectCount : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            standaloneProjectCount: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.standaloneProjectCount : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            activeAssociatedSubscription: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.activeAssociatedSubscription : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            associatedSubscriptions: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.associatedSubscriptions : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: UserInfo.isValid,
        };
    }

    static isValid(obj: UserInfo): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = UserInfo.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.firstName.isValid(item);
            result = result && validationObject.lastName.isValid(item);
            result = result && validationObject.organizationIdentifier.isValid(item);
            result = result && validationObject.organizationName.isValid(item);
            result = result && validationObject.email.isValid(item);
            result = result && validationObject.username.isValid(item);
            result = result && validationObject.timezone.isValid(item);
            result = result && validationObject.joinedDate.isValid(item);
            result = result && validationObject.lastLoginDate.isValid(item);
            result = result && validationObject.miradiSubscription.isValid(item);
            result = result && validationObject.apiAccessEnabled.isValid(item);
            result = result && validationObject.isBetaTester.isValid(item);
            result = result && validationObject.programs.isValid(item);
            result = result && validationObject.totalProjectCount.isValid(item);
            result = result && validationObject.standaloneProjectCount.isValid(item);
            result = result && validationObject.activeAssociatedSubscription.isValid(item);
            result = result && validationObject.associatedSubscriptions.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

