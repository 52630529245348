/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Taxonomy classification (new)
 */
import { ValidateUtils } from 'app/shared/utils';

export class TaxonomyClassificationNew { 
    /**
     * Unique identifier for Taxonomy Version
     */
    taxonomyVersionResourceIdentifier?: string;
    /**
     * Taxonomy classification element selections
     */
    taxonomyElementSelections?: Array<string>;

    constructor(item?: Partial<TaxonomyClassificationNew>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            taxonomyVersionResourceIdentifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.taxonomyVersionResourceIdentifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            taxonomyElementSelections: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.taxonomyElementSelections : itemOrValue;

                    if (itemOrValue?.taxonomyElementSelections != null) {
                        if (itemOrValue.taxonomyElementSelections === '') {
                            itemOrValue.taxonomyElementSelections = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.taxonomyElementSelections = parseInt(itemOrValue.taxonomyElementSelections.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.taxonomyElementSelections = parseFloat(itemOrValue.taxonomyElementSelections.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: TaxonomyClassificationNew.isValid,
        };
    }

    static isValid(obj: TaxonomyClassificationNew): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = TaxonomyClassificationNew.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.taxonomyVersionResourceIdentifier.isValid(item);
            result = result && validationObject.taxonomyElementSelections.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

