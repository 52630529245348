/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FactorProgress } from './factorProgress';
import { ProgressReport } from './progressReport';


/**
 * Hierarchical view of project progress
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectProgress { 
    /**
     * Unique identifier
     */
    readonly identifier?: string;
    /**
     * Project name
     */
    readonly name?: string;
    latestProgressReport?: ProgressReport;
    /**
     * Results Chain diagrams for Project
     */
    readonly resultsChains?: Array<FactorProgress>;
    /**
     * Conceptual Model diagrams for Project
     */
    readonly conceptualModels?: Array<FactorProgress>;

    constructor(item?: Partial<ProjectProgress>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            identifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            latestProgressReport: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.latestProgressReport : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            resultsChains: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resultsChains : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            conceptualModels: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.conceptualModels : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProjectProgress.isValid,
        };
    }

    static isValid(obj: ProjectProgress): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectProgress.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.latestProgressReport.isValid(item);
            result = result && validationObject.resultsChains.isValid(item);
            result = result && validationObject.conceptualModels.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

