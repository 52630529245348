/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Info parsed from project file
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectFileInfo { 
    /**
     * Unique identifier
     */
    readonly identifier?: string;
    /**
     * Project name
     */
    readonly name?: string;
    /**
     * Program identifier
     */
    readonly programIdentifier?: string;
    /**
     * Program name
     */
    readonly programName?: string;
    /**
     * Unique identifier
     */
    readonly programTemplateIdentifier?: string;
    /**
     * Template name
     */
    readonly programTemplateName?: string;
    /**
     * Project version number
     */
    readonly versionNumber?: string;
    /**
     * Miradi version of file
     */
    readonly miradiVersion?: string;

    constructor(item?: Partial<ProjectFileInfo>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            identifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            programIdentifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.programIdentifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            programName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.programName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            programTemplateIdentifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.programTemplateIdentifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            programTemplateName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.programTemplateName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            versionNumber: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.versionNumber : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            miradiVersion: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.miradiVersion : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProjectFileInfo.isValid,
        };
    }

    static isValid(obj: ProjectFileInfo): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectFileInfo.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.programIdentifier.isValid(item);
            result = result && validationObject.programName.isValid(item);
            result = result && validationObject.programTemplateIdentifier.isValid(item);
            result = result && validationObject.programTemplateName.isValid(item);
            result = result && validationObject.versionNumber.isValid(item);
            result = result && validationObject.miradiVersion.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

