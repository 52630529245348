/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Project timeframe (new / update)
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectTimeframeNew { 
    /**
     * Project start date
     */
    startDate: string;
    /**
     * Project end date (expected)
     */
    endDate: string;
    /**
     * Work plan start date
     */
    workPlanStartDate: string;
    /**
     * Work plan end date
     */
    workPlanEndDate: string;

    constructor(item?: Partial<ProjectTimeframeNew>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            startDate: {
                type: 'date',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.startDate : itemOrValue;

                    if (itemOrValue?.startDate != null) {
                        if (itemOrValue.startDate === '') {
                            itemOrValue.startDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date') >= 0) {
                            itemOrValue.startDate = parseInt(itemOrValue.startDate.toString(), 10);
                        } else if (['float'].indexOf('date') >= 0) {
                            itemOrValue.startDate = parseFloat(itemOrValue.startDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            endDate: {
                type: 'date',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.endDate : itemOrValue;

                    if (itemOrValue?.endDate != null) {
                        if (itemOrValue.endDate === '') {
                            itemOrValue.endDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date') >= 0) {
                            itemOrValue.endDate = parseInt(itemOrValue.endDate.toString(), 10);
                        } else if (['float'].indexOf('date') >= 0) {
                            itemOrValue.endDate = parseFloat(itemOrValue.endDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            workPlanStartDate: {
                type: 'date',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.workPlanStartDate : itemOrValue;

                    if (itemOrValue?.workPlanStartDate != null) {
                        if (itemOrValue.workPlanStartDate === '') {
                            itemOrValue.workPlanStartDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date') >= 0) {
                            itemOrValue.workPlanStartDate = parseInt(itemOrValue.workPlanStartDate.toString(), 10);
                        } else if (['float'].indexOf('date') >= 0) {
                            itemOrValue.workPlanStartDate = parseFloat(itemOrValue.workPlanStartDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            workPlanEndDate: {
                type: 'date',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.workPlanEndDate : itemOrValue;

                    if (itemOrValue?.workPlanEndDate != null) {
                        if (itemOrValue.workPlanEndDate === '') {
                            itemOrValue.workPlanEndDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date') >= 0) {
                            itemOrValue.workPlanEndDate = parseInt(itemOrValue.workPlanEndDate.toString(), 10);
                        } else if (['float'].indexOf('date') >= 0) {
                            itemOrValue.workPlanEndDate = parseFloat(itemOrValue.workPlanEndDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProjectTimeframeNew.isValid,
        };
    }

    static isValid(obj: ProjectTimeframeNew): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectTimeframeNew.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.startDate.isValid(item);
            result = result && validationObject.endDate.isValid(item);
            result = result && validationObject.workPlanStartDate.isValid(item);
            result = result && validationObject.workPlanEndDate.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

