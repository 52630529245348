/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DiagramFactorType } from './diagramFactorType';
import { DiagramType } from './diagramType';


/**
 * Miradi diagram factor types (by diagram type)
 */
import { ValidateUtils } from 'app/shared/utils';

export class DiagramFactorTypeDict { 
    diagramType?: DiagramType;
    /**
     * Supported diagram factor types
     */
    readonly diagramFactorTypes?: Array<DiagramFactorType>;

    constructor(item?: Partial<DiagramFactorTypeDict>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            diagramType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.diagramType : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            diagramFactorTypes: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.diagramFactorTypes : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: DiagramFactorTypeDict.isValid,
        };
    }

    static isValid(obj: DiagramFactorTypeDict): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = DiagramFactorTypeDict.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.diagramType.isValid(item);
            result = result && validationObject.diagramFactorTypes.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

