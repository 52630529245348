/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WorkDateUnitEnum } from './workDateUnitEnum';


/**
 * Factor expense assignment details
 */
import { ValidateUtils } from 'app/shared/utils';

export class FactorExpenseDetail { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    expenseDateUnitType?: WorkDateUnitEnum;
    /**
     * Expense date
     */
    expenseDate?: string;
    /**
     * Expense amount
     */
    expenseAmount?: number | null;

    constructor(item?: Partial<FactorExpenseDetail>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            expenseDateUnitType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.expenseDateUnitType : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            expenseDate: {
                type: 'date',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.expenseDate : itemOrValue;

                    if (itemOrValue?.expenseDate != null) {
                        if (itemOrValue.expenseDate === '') {
                            itemOrValue.expenseDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date') >= 0) {
                            itemOrValue.expenseDate = parseInt(itemOrValue.expenseDate.toString(), 10);
                        } else if (['float'].indexOf('date') >= 0) {
                            itemOrValue.expenseDate = parseFloat(itemOrValue.expenseDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            expenseAmount: {
                type: 'float',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.expenseAmount : itemOrValue;

                    if (itemOrValue?.expenseAmount != null) {
                        if (itemOrValue.expenseAmount === '') {
                            itemOrValue.expenseAmount = undefined;
                        } else if (['int32', 'int64'].indexOf('float') >= 0) {
                            itemOrValue.expenseAmount = parseInt(itemOrValue.expenseAmount.toString(), 10);
                        } else if (['float'].indexOf('float') >= 0) {
                            itemOrValue.expenseAmount = parseFloat(itemOrValue.expenseAmount.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'float');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: FactorExpenseDetail.isValid,
        };
    }

    static isValid(obj: FactorExpenseDetail): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = FactorExpenseDetail.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.expenseDateUnitType.isValid(item);
            result = result && validationObject.expenseDate.isValid(item);
            result = result && validationObject.expenseAmount.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

