/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Child diagram factors for Group Box
 */
import { ValidateUtils } from 'app/shared/utils';

export class GroupBoxChildDiagramFactors { 
    /**
     * Child diagram factors
     */
    childDiagramFactorResourceIdentifiers?: Array<string>;

    constructor(item?: Partial<GroupBoxChildDiagramFactors>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            childDiagramFactorResourceIdentifiers: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.childDiagramFactorResourceIdentifiers : itemOrValue;

                    if (itemOrValue?.childDiagramFactorResourceIdentifiers != null) {
                        if (itemOrValue.childDiagramFactorResourceIdentifiers === '') {
                            itemOrValue.childDiagramFactorResourceIdentifiers = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.childDiagramFactorResourceIdentifiers = parseInt(itemOrValue.childDiagramFactorResourceIdentifiers.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.childDiagramFactorResourceIdentifiers = parseFloat(itemOrValue.childDiagramFactorResourceIdentifiers.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: GroupBoxChildDiagramFactors.isValid,
        };
    }

    static isValid(obj: GroupBoxChildDiagramFactors): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = GroupBoxChildDiagramFactors.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.childDiagramFactorResourceIdentifiers.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

