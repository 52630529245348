/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FactorType } from './factorType';
import { FactorFootprint } from './factorFootprint';


/**
 * Factor map data (by Type)
 */
import { ValidateUtils } from 'app/shared/utils';

export class FactorTypeFootprint { 
    factorType?: FactorType;
    /**
     * Factor type layer options (color, fillColor, weight, etc.)
     */
    readonly layerOptions?: object;
    /**
     * List of factor footprints
     */
    readonly factorFootprints?: Array<FactorFootprint>;

    constructor(item?: Partial<FactorTypeFootprint>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            factorType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorType : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            layerOptions: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.layerOptions : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            factorFootprints: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorFootprints : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: FactorTypeFootprint.isValid,
        };
    }

    static isValid(obj: FactorTypeFootprint): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = FactorTypeFootprint.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.factorType.isValid(item);
            result = result && validationObject.layerOptions.isValid(item);
            result = result && validationObject.factorFootprints.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

