/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FactorExpenseDetail } from './factorExpenseDetail';
import { TaxonomyClassification } from './taxonomyClassification';


/**
 * Factor expense assignment
 */
import { ValidateUtils } from 'app/shared/utils';

export class FactorExpense { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    /**
     * Expense name
     */
    expenseName?: string;
    /**
     * Expense assignment details
     */
    expenseDetails?: Array<FactorExpenseDetail>;
    /**
     * Expense assignment accounting classifications
     */
    accountingClassifications?: Array<TaxonomyClassification>;
    /**
     * Expense assignment taxonomy classifications
     */
    taxonomyClassifications?: Array<TaxonomyClassification>;

    constructor(item?: Partial<FactorExpense>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            expenseName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.expenseName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            expenseDetails: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.expenseDetails : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            accountingClassifications: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.accountingClassifications : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            taxonomyClassifications: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.taxonomyClassifications : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: FactorExpense.isValid,
        };
    }

    static isValid(obj: FactorExpense): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = FactorExpense.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.expenseName.isValid(item);
            result = result && validationObject.expenseDetails.isValid(item);
            result = result && validationObject.accountingClassifications.isValid(item);
            result = result && validationObject.taxonomyClassifications.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

