/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Program portfolio (new / update)
 */
import { ValidateUtils } from 'app/shared/utils';

export class PortfolioNew { 
    /**
     * Portfolio name
     */
    portfolioName?: string;
    /**
     * Portfolio description
     */
    portfolioDescription?: string;
    /**
     * Portfolio guidance
     */
    portfolioGuidance?: string;
    /**
     * Relevant Projects for Portfolio
     */
    relevantProjectResourceIdentifiers?: Array<string>;
    /**
     * List of tags for filtering
     */
    filterTags?: Array<string>;

    constructor(item?: Partial<PortfolioNew>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            portfolioName: {
                
                minLength: 1,
                maxLength: 200,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.portfolioName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 1, 200);

                    return result;
                }
            },
            portfolioDescription: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.portfolioDescription : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            portfolioGuidance: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.portfolioGuidance : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            relevantProjectResourceIdentifiers: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.relevantProjectResourceIdentifiers : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            filterTags: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.filterTags : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: PortfolioNew.isValid,
        };
    }

    static isValid(obj: PortfolioNew): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = PortfolioNew.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.portfolioName.isValid(item);
            result = result && validationObject.portfolioDescription.isValid(item);
            result = result && validationObject.portfolioGuidance.isValid(item);
            result = result && validationObject.relevantProjectResourceIdentifiers.isValid(item);
            result = result && validationObject.filterTags.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

