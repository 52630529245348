/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IndicatorViabilityRatingsNew } from './indicatorViabilityRatingsNew';
import { IndicatorThreshold } from './indicatorThreshold';
import { IndicatorFutureStatusAllOf } from './indicatorFutureStatusAllOf';
import { EvidenceConfidenceExternalEnum } from './evidenceConfidenceExternalEnum';


/**
 * Indicator viability
 */
import { ValidateUtils } from 'app/shared/utils';

export class IndicatorViabilityRatings { 
    evidenceConfidence?: EvidenceConfidenceExternalEnum;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    comments?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    evidenceNotes?: string;
    /**
     * Indicator Thresholds
     */
    thresholds?: Array<IndicatorThreshold>;
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier: string;

    constructor(item?: Partial<IndicatorViabilityRatings>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            evidenceConfidence: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.evidenceConfidence : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            comments: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.comments : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            evidenceNotes: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.evidenceNotes : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            thresholds: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.thresholds : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            resourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: IndicatorViabilityRatings.isValid,
        };
    }

    static isValid(obj: IndicatorViabilityRatings): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = IndicatorViabilityRatings.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.evidenceConfidence.isValid(item);
            result = result && validationObject.comments.isValid(item);
            result = result && validationObject.evidenceNotes.isValid(item);
            result = result && validationObject.thresholds.isValid(item);
            result = result && validationObject.resourceIdentifier.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

