/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectDiagramGuideContent } from './projectDiagramGuideContent';


/**
 * Project diagram guide
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectDiagramGuide { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    /**
     * Miradi object resource identifier
     */
    projectDiagramResourceIdentifier?: string;
    /**
     * Date diagram guide last updated
     */
    readonly lastUpdatedDate?: string;
    /**
     * Resource identifier for User who last updated diagram guide (if applicable)
     */
    readonly lastUpdatedByResourceIdentifier?: string;
    /**
     * Full name of User who who last updated diagram guide (if applicable)
     */
    readonly lastUpdatedByFullName?: string;
    guideContent?: ProjectDiagramGuideContent;

    constructor(item?: Partial<ProjectDiagramGuide>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            projectDiagramResourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectDiagramResourceIdentifier : itemOrValue;

                    if (itemOrValue?.projectDiagramResourceIdentifier != null) {
                        if (itemOrValue.projectDiagramResourceIdentifier === '') {
                            itemOrValue.projectDiagramResourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.projectDiagramResourceIdentifier = parseInt(itemOrValue.projectDiagramResourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.projectDiagramResourceIdentifier = parseFloat(itemOrValue.projectDiagramResourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            lastUpdatedDate: {
                type: 'date-time',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastUpdatedDate : itemOrValue;

                    if (itemOrValue?.lastUpdatedDate != null) {
                        if (itemOrValue.lastUpdatedDate === '') {
                            itemOrValue.lastUpdatedDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date-time') >= 0) {
                            itemOrValue.lastUpdatedDate = parseInt(itemOrValue.lastUpdatedDate.toString(), 10);
                        } else if (['float'].indexOf('date-time') >= 0) {
                            itemOrValue.lastUpdatedDate = parseFloat(itemOrValue.lastUpdatedDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date-time');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            lastUpdatedByResourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastUpdatedByResourceIdentifier : itemOrValue;

                    if (itemOrValue?.lastUpdatedByResourceIdentifier != null) {
                        if (itemOrValue.lastUpdatedByResourceIdentifier === '') {
                            itemOrValue.lastUpdatedByResourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.lastUpdatedByResourceIdentifier = parseInt(itemOrValue.lastUpdatedByResourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.lastUpdatedByResourceIdentifier = parseFloat(itemOrValue.lastUpdatedByResourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            lastUpdatedByFullName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastUpdatedByFullName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            guideContent: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.guideContent : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProjectDiagramGuide.isValid,
        };
    }

    static isValid(obj: ProjectDiagramGuide): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectDiagramGuide.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.projectDiagramResourceIdentifier.isValid(item);
            result = result && validationObject.lastUpdatedDate.isValid(item);
            result = result && validationObject.lastUpdatedByResourceIdentifier.isValid(item);
            result = result && validationObject.lastUpdatedByFullName.isValid(item);
            result = result && validationObject.guideContent.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

