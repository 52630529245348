/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Project document image (new / update)
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectDocumentImageNew { 
    /**
     * Image file bytes (supported content types are png, jpeg, gif, bmp and tif. Max file size allowed is 15MB. All images will be converted to .png.)
     */
    imageFileBytes?: Blob;
    /**
     * Document reference
     */
    documentReference?: string;

    constructor(item?: Partial<ProjectDocumentImageNew>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            imageFileBytes: {
                type: 'binary',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.imageFileBytes : itemOrValue;

                    if (itemOrValue?.imageFileBytes != null) {
                        if (itemOrValue.imageFileBytes === '') {
                            itemOrValue.imageFileBytes = undefined;
                        } else if (['int32', 'int64'].indexOf('binary') >= 0) {
                            itemOrValue.imageFileBytes = parseInt(itemOrValue.imageFileBytes.toString(), 10);
                        } else if (['float'].indexOf('binary') >= 0) {
                            itemOrValue.imageFileBytes = parseFloat(itemOrValue.imageFileBytes.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'binary');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            documentReference: {
                
                minLength: 1,
                maxLength: 1024,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.documentReference : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 1, 1024);

                    return result;
                }
            },
            isValid: ProjectDocumentImageNew.isValid,
        };
    }

    static isValid(obj: ProjectDocumentImageNew): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectDocumentImageNew.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.imageFileBytes.isValid(item);
            result = result && validationObject.documentReference.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

