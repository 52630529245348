/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Program map layer file (new / update)
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProgramMapLayerNew { 
    /**
     * GIS shapefile (zip archive)
     */
    shapeFileBytes?: Blob;
    /**
     * Map layer name
     */
    name?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    description?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    source?: string;

    constructor(item?: Partial<ProgramMapLayerNew>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            shapeFileBytes: {
                type: 'binary',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.shapeFileBytes : itemOrValue;

                    if (itemOrValue?.shapeFileBytes != null) {
                        if (itemOrValue.shapeFileBytes === '') {
                            itemOrValue.shapeFileBytes = undefined;
                        } else if (['int32', 'int64'].indexOf('binary') >= 0) {
                            itemOrValue.shapeFileBytes = parseInt(itemOrValue.shapeFileBytes.toString(), 10);
                        } else if (['float'].indexOf('binary') >= 0) {
                            itemOrValue.shapeFileBytes = parseFloat(itemOrValue.shapeFileBytes.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'binary');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            name: {
                
                minLength: 1,
                maxLength: 1024,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 1, 1024);

                    return result;
                }
            },
            description: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.description : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            source: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.source : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProgramMapLayerNew.isValid,
        };
    }

    static isValid(obj: ProgramMapLayerNew): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProgramMapLayerNew.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.shapeFileBytes.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.description.isValid(item);
            result = result && validationObject.source.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

