/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ErrorCodeEnum } from './errorCodeEnum';


/**
 * Error detail
 */
import { ValidateUtils } from 'app/shared/utils';

export class ErrorDetail { 
    errorCode?: ErrorCodeEnum;
    /**
     * Error detail
     */
    readonly detail?: string;

    constructor(item?: Partial<ErrorDetail>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            errorCode: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.errorCode : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            detail: {
                
                minLength: 0,
                maxLength: 1024,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.detail : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 0, 1024);

                    return result;
                }
            },
            isValid: ErrorDetail.isValid,
        };
    }

    static isValid(obj: ErrorDetail): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ErrorDetail.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.errorCode.isValid(item);
            result = result && validationObject.detail.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

