/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectTeamMember } from './projectTeamMember';
import { ProjectRoleEnum } from './projectRoleEnum';


/**
 * Project owner
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectOwner { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    projectRole?: ProjectRoleEnum;
    /**
     * Member first name
     */
    readonly firstName?: string;
    /**
     * Member last name
     */
    readonly lastName?: string;
    /**
     * Member username
     */
    readonly username?: string;
    /**
     * Member email
     */
    readonly email?: string;
    /**
     * Member organization
     */
    readonly organization?: string;

    constructor(item?: Partial<ProjectOwner>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            projectRole: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectRole : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            firstName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.firstName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            lastName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            username: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.username : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            email: {
                type: 'email',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.email : itemOrValue;

                    if (itemOrValue?.email != null) {
                        if (itemOrValue.email === '') {
                            itemOrValue.email = undefined;
                        } else if (['int32', 'int64'].indexOf('email') >= 0) {
                            itemOrValue.email = parseInt(itemOrValue.email.toString(), 10);
                        } else if (['float'].indexOf('email') >= 0) {
                            itemOrValue.email = parseFloat(itemOrValue.email.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'email');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            organization: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.organization : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProjectOwner.isValid,
        };
    }

    static isValid(obj: ProjectOwner): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectOwner.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.projectRole.isValid(item);
            result = result && validationObject.firstName.isValid(item);
            result = result && validationObject.lastName.isValid(item);
            result = result && validationObject.username.isValid(item);
            result = result && validationObject.email.isValid(item);
            result = result && validationObject.organization.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

