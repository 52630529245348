/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IndicatorNew } from './indicatorNew';
import { IndicatorFutureStatusNew } from './indicatorFutureStatusNew';
import { TaxonomyClassificationNew } from './taxonomyClassificationNew';
import { ObjectiveNew } from './objectiveNew';
import { IndicatorMeasurementNew } from './indicatorMeasurementNew';


/**
 * Objective and related (created via SMART guide)
 */
import { ValidateUtils } from 'app/shared/utils';

export class SmartObjectiveNew { 
    objective?: ObjectiveNew;
    /**
     * Objective taxonomy classifications
     */
    objectiveTaxonomyClassifications?: Array<TaxonomyClassificationNew>;
    indicator?: IndicatorNew;
    /**
     * Indicator taxonomy classifications
     */
    indicatorTaxonomyClassifications?: Array<TaxonomyClassificationNew>;
    indicatorFutureStatus?: IndicatorFutureStatusNew;
    indicatorMeasurement?: IndicatorMeasurementNew;

    constructor(item?: Partial<SmartObjectiveNew>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            objective: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.objective : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            objectiveTaxonomyClassifications: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.objectiveTaxonomyClassifications : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            indicator: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.indicator : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            indicatorTaxonomyClassifications: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.indicatorTaxonomyClassifications : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            indicatorFutureStatus: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.indicatorFutureStatus : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            indicatorMeasurement: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.indicatorMeasurement : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: SmartObjectiveNew.isValid,
        };
    }

    static isValid(obj: SmartObjectiveNew): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = SmartObjectiveNew.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.objective.isValid(item);
            result = result && validationObject.objectiveTaxonomyClassifications.isValid(item);
            result = result && validationObject.indicator.isValid(item);
            result = result && validationObject.indicatorTaxonomyClassifications.isValid(item);
            result = result && validationObject.indicatorFutureStatus.isValid(item);
            result = result && validationObject.indicatorMeasurement.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

