/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectDiagramFactor } from './projectDiagramFactor';
import { ProjectDiagramLink } from './projectDiagramLink';


/**
 * Project diagram factor chain (diagram fragment)
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectDiagramFactorChain { 
    /**
     * Diagramn factors
     */
    diagramFactors?: Array<ProjectDiagramFactor>;
    /**
     * Diagram links
     */
    diagramLinks?: Array<ProjectDiagramLink>;

    constructor(item?: Partial<ProjectDiagramFactorChain>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            diagramFactors: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.diagramFactors : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            diagramLinks: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.diagramLinks : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProjectDiagramFactorChain.isValid,
        };
    }

    static isValid(obj: ProjectDiagramFactorChain): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectDiagramFactorChain.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.diagramFactors.isValid(item);
            result = result && validationObject.diagramLinks.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

