/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Program summary info (update)
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProgramSummaryUpdate { 
    /**
     * Program name (must be globally unique)
     */
    name?: string;
    /**
     * Program short name
     */
    shortName?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    description?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    tagline?: string;
    /**
     * Flag indicating whether Program is private (or public)
     */
    isPrivate?: boolean;
    /**
     * Miradi object resource identifier
     */
    contactResourceIdentifier?: string;

    constructor(item?: Partial<ProgramSummaryUpdate>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            name: {
                
                minLength: 1,
                maxLength: 200,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 1, 200);

                    return result;
                }
            },
            shortName: {
                
                minLength: 1,
                maxLength: 50,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.shortName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 1, 50);

                    return result;
                }
            },
            description: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.description : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            tagline: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.tagline : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isPrivate: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isPrivate : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            contactResourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.contactResourceIdentifier : itemOrValue;

                    if (itemOrValue?.contactResourceIdentifier != null) {
                        if (itemOrValue.contactResourceIdentifier === '') {
                            itemOrValue.contactResourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.contactResourceIdentifier = parseInt(itemOrValue.contactResourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.contactResourceIdentifier = parseFloat(itemOrValue.contactResourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProgramSummaryUpdate.isValid,
        };
    }

    static isValid(obj: ProgramSummaryUpdate): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProgramSummaryUpdate.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.shortName.isValid(item);
            result = result && validationObject.description.isValid(item);
            result = result && validationObject.tagline.isValid(item);
            result = result && validationObject.isPrivate.isValid(item);
            result = result && validationObject.contactResourceIdentifier.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

