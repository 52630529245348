/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MiradiFeatureFlag } from './miradiFeatureFlag';
import { ProjectOwner } from './projectOwner';
import { ProjectRoleEnum } from './projectRoleEnum';
import { FactorKeyValuePair } from './factorKeyValuePair';
import { ProjectPublishStatusEnum } from './projectPublishStatusEnum';
import { TaxonomyClassification } from './taxonomyClassification';
import { ProjectAccessEnum } from './projectAccessEnum';


/**
 * Miradi Share Project
 */
import { ValidateUtils } from 'app/shared/utils';

export class Project { 
    /**
     * Unique identifier
     */
    readonly identifier?: string;
    /**
     * Project name
     */
    readonly name: string;
    /**
     * Program identifier
     */
    readonly programIdentifier?: string;
    /**
     * Program name
     */
    readonly programName?: string;
    /**
     * Portfolios project is part of
     */
    portfolios?: Array<FactorKeyValuePair>;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    description?: string;
    publishStatus?: ProjectPublishStatusEnum;
    access?: ProjectAccessEnum;
    /**
     * Most recent activity for Project
     */
    readonly activityLabel?: string;
    /**
     * Flag indicating whether Project is currently checked-out
     */
    readonly isCheckedOut?: boolean;
    /**
     * Resource identifier for User who carried out activity with Project (if applicable)
     */
    readonly activityByResourceIdentifier?: string;
    /**
     * Full name of User who who carried out activity with Project (if applicable)
     */
    readonly activityByFullName?: string;
    /**
     * Date of last activity for Project (if applicable)
     */
    readonly activityDate?: string;
    /**
     * Flag indicating whether Project is currently pending import
     */
    readonly isPendingImport?: boolean;
    /**
     * Comma-delimited list of countries that Project applies to
     */
    readonly countries?: string;
    /**
     * Project version number
     */
    readonly versionNumber?: string;
    /**
     * Miradi version of underlying Project file
     */
    readonly miradiVersion?: string;
    /**
     * Template name
     */
    readonly templateName?: string;
    /**
     * Flag indicating whether Project is marked as a favorite (only populated when user is authenticated)
     */
    readonly isFavorite?: boolean | null;
    projectOwner?: ProjectOwner;
    projectRole?: ProjectRoleEnum;
    /**
     * Project taxonomy classifications (only populated when list of Projects is scoped to a Program)
     */
    taxonomyClassifications?: Array<TaxonomyClassification>;
    /**
     * Miradi features supported by Project (only populated when return type is for a single Project)
     */
    miradiFeatures?: Array<MiradiFeatureFlag>;

    constructor(item?: Partial<Project>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            identifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            programIdentifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.programIdentifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            programName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.programName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            portfolios: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.portfolios : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            description: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.description : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            publishStatus: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.publishStatus : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            access: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.access : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            activityLabel: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.activityLabel : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isCheckedOut: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isCheckedOut : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            activityByResourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.activityByResourceIdentifier : itemOrValue;

                    if (itemOrValue?.activityByResourceIdentifier != null) {
                        if (itemOrValue.activityByResourceIdentifier === '') {
                            itemOrValue.activityByResourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.activityByResourceIdentifier = parseInt(itemOrValue.activityByResourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.activityByResourceIdentifier = parseFloat(itemOrValue.activityByResourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            activityByFullName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.activityByFullName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            activityDate: {
                type: 'date-time',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.activityDate : itemOrValue;

                    if (itemOrValue?.activityDate != null) {
                        if (itemOrValue.activityDate === '') {
                            itemOrValue.activityDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date-time') >= 0) {
                            itemOrValue.activityDate = parseInt(itemOrValue.activityDate.toString(), 10);
                        } else if (['float'].indexOf('date-time') >= 0) {
                            itemOrValue.activityDate = parseFloat(itemOrValue.activityDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date-time');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isPendingImport: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isPendingImport : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            countries: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.countries : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            versionNumber: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.versionNumber : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            miradiVersion: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.miradiVersion : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            templateName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.templateName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isFavorite: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isFavorite : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            projectOwner: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectOwner : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            projectRole: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectRole : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            taxonomyClassifications: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.taxonomyClassifications : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            miradiFeatures: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.miradiFeatures : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: Project.isValid,
        };
    }

    static isValid(obj: Project): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = Project.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.programIdentifier.isValid(item);
            result = result && validationObject.programName.isValid(item);
            result = result && validationObject.portfolios.isValid(item);
            result = result && validationObject.description.isValid(item);
            result = result && validationObject.publishStatus.isValid(item);
            result = result && validationObject.access.isValid(item);
            result = result && validationObject.activityLabel.isValid(item);
            result = result && validationObject.isCheckedOut.isValid(item);
            result = result && validationObject.activityByResourceIdentifier.isValid(item);
            result = result && validationObject.activityByFullName.isValid(item);
            result = result && validationObject.activityDate.isValid(item);
            result = result && validationObject.isPendingImport.isValid(item);
            result = result && validationObject.countries.isValid(item);
            result = result && validationObject.versionNumber.isValid(item);
            result = result && validationObject.miradiVersion.isValid(item);
            result = result && validationObject.templateName.isValid(item);
            result = result && validationObject.isFavorite.isValid(item);
            result = result && validationObject.projectOwner.isValid(item);
            result = result && validationObject.projectRole.isValid(item);
            result = result && validationObject.taxonomyClassifications.isValid(item);
            result = result && validationObject.miradiFeatures.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

