/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MaintenanceBanner } from './maintenanceBanner';


/**
 * System configuration settings
 */
import { ValidateUtils } from 'app/shared/utils';

export class SystemSettings { 
    /**
     * Miradi license effective date
     */
    miradiLicenseEffectiveDate?: string | null;
    /**
     * List of currently supported Miradi versions
     */
    supportedMiradiVersions?: Array<string>;
    maintenanceBanner?: MaintenanceBanner;

    constructor(item?: Partial<SystemSettings>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            miradiLicenseEffectiveDate: {
                type: 'date',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.miradiLicenseEffectiveDate : itemOrValue;

                    if (itemOrValue?.miradiLicenseEffectiveDate != null) {
                        if (itemOrValue.miradiLicenseEffectiveDate === '') {
                            itemOrValue.miradiLicenseEffectiveDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date') >= 0) {
                            itemOrValue.miradiLicenseEffectiveDate = parseInt(itemOrValue.miradiLicenseEffectiveDate.toString(), 10);
                        } else if (['float'].indexOf('date') >= 0) {
                            itemOrValue.miradiLicenseEffectiveDate = parseFloat(itemOrValue.miradiLicenseEffectiveDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            supportedMiradiVersions: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.supportedMiradiVersions : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            maintenanceBanner: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.maintenanceBanner : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: SystemSettings.isValid,
        };
    }

    static isValid(obj: SystemSettings): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = SystemSettings.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.miradiLicenseEffectiveDate.isValid(item);
            result = result && validationObject.supportedMiradiVersions.isValid(item);
            result = result && validationObject.maintenanceBanner.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

