/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Project custom report group
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectCustomReportGroup { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    /**
     * Custom report group name
     */
    name?: string;
    /**
     * Custom report group description
     */
    description?: string;
    /**
     * Date group last updated
     */
    readonly lastUpdatedDate?: string;
    /**
     * Resource identifier for User who last updated group (if applicable)
     */
    readonly lastUpdatedByResourceIdentifier?: string;
    /**
     * Full name of User who who last updated group (if applicable)
     */
    readonly lastUpdatedByFullName?: string;
    /**
     * Relevant Custom Reports for custom report group
     */
    relevantCustomReportResourceIdentifiers?: Array<string>;

    constructor(item?: Partial<ProjectCustomReportGroup>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            name: {
                
                minLength: 1,
                maxLength: 200,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 1, 200);

                    return result;
                }
            },
            description: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.description : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            lastUpdatedDate: {
                type: 'date-time',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastUpdatedDate : itemOrValue;

                    if (itemOrValue?.lastUpdatedDate != null) {
                        if (itemOrValue.lastUpdatedDate === '') {
                            itemOrValue.lastUpdatedDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date-time') >= 0) {
                            itemOrValue.lastUpdatedDate = parseInt(itemOrValue.lastUpdatedDate.toString(), 10);
                        } else if (['float'].indexOf('date-time') >= 0) {
                            itemOrValue.lastUpdatedDate = parseFloat(itemOrValue.lastUpdatedDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date-time');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            lastUpdatedByResourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastUpdatedByResourceIdentifier : itemOrValue;

                    if (itemOrValue?.lastUpdatedByResourceIdentifier != null) {
                        if (itemOrValue.lastUpdatedByResourceIdentifier === '') {
                            itemOrValue.lastUpdatedByResourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.lastUpdatedByResourceIdentifier = parseInt(itemOrValue.lastUpdatedByResourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.lastUpdatedByResourceIdentifier = parseFloat(itemOrValue.lastUpdatedByResourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            lastUpdatedByFullName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastUpdatedByFullName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            relevantCustomReportResourceIdentifiers: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.relevantCustomReportResourceIdentifiers : itemOrValue;

                    if (itemOrValue?.relevantCustomReportResourceIdentifiers != null) {
                        if (itemOrValue.relevantCustomReportResourceIdentifiers === '') {
                            itemOrValue.relevantCustomReportResourceIdentifiers = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.relevantCustomReportResourceIdentifiers = parseInt(itemOrValue.relevantCustomReportResourceIdentifiers.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.relevantCustomReportResourceIdentifiers = parseFloat(itemOrValue.relevantCustomReportResourceIdentifiers.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProjectCustomReportGroup.isValid,
        };
    }

    static isValid(obj: ProjectCustomReportGroup): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectCustomReportGroup.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.description.isValid(item);
            result = result && validationObject.lastUpdatedDate.isValid(item);
            result = result && validationObject.lastUpdatedByResourceIdentifier.isValid(item);
            result = result && validationObject.lastUpdatedByFullName.isValid(item);
            result = result && validationObject.relevantCustomReportResourceIdentifiers.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

