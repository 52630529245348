/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Project snapshot (new)
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectSnapshotNew { 
    /**
     * Snapshot name
     */
    name?: string;
    /**
     * Snapshot description
     */
    description?: string;

    constructor(item?: Partial<ProjectSnapshotNew>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            name: {
                
                minLength: 1,
                maxLength: 200,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 1, 200);

                    return result;
                }
            },
            description: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.description : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProjectSnapshotNew.isValid,
        };
    }

    static isValid(obj: ProjectSnapshotNew): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectSnapshotNew.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.description.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

