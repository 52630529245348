/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Indicator method
 */
import { ValidateUtils } from 'app/shared/utils';

export class IndicatorMethodNew { 
    /**
     * Factor identifier
     */
    identifier?: string;
    /**
     * Method name
     */
    name?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    details?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    comments?: string;
    /**
     * Method url
     */
    url?: string | null;

    constructor(item?: Partial<IndicatorMethodNew>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            identifier: {
                
                minLength: 0,
                maxLength: 255,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 0, 255);

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            details: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.details : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            comments: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.comments : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            url: {
                type: 'uri',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.url : itemOrValue;

                    if (itemOrValue?.url != null) {
                        if (itemOrValue.url === '') {
                            itemOrValue.url = undefined;
                        } else if (['int32', 'int64'].indexOf('uri') >= 0) {
                            itemOrValue.url = parseInt(itemOrValue.url.toString(), 10);
                        } else if (['float'].indexOf('uri') >= 0) {
                            itemOrValue.url = parseFloat(itemOrValue.url.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uri');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: IndicatorMethodNew.isValid,
        };
    }

    static isValid(obj: IndicatorMethodNew): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = IndicatorMethodNew.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.details.isValid(item);
            result = result && validationObject.comments.isValid(item);
            result = result && validationObject.url.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

