/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DiagramFactorSizeType } from './diagramFactorSizeType';
import { DiagramFactorLocationType } from './diagramFactorLocationType';
import { FactorType } from './factorType';
import { IndicatorFutureStatusNew } from './indicatorFutureStatusNew';
import { IndicatorMeasurementNew } from './indicatorMeasurementNew';


/**
 * Guide factor info
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectDiagramGuideFactorInfo { 
    /**
     * Miradi object resource identifier
     */
    clientResourceIdentifier?: string;
    factorType?: FactorType;
    /**
     * Factor identifier
     */
    identifier?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    name?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    details?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    comments?: string;
    /**
     * Indicator unit
     */
    indicatorUnit?: string | null;
    indicatorFutureStatus?: IndicatorFutureStatusNew;
    indicatorMeasurement?: IndicatorMeasurementNew;
    location?: DiagramFactorLocationType;
    size?: DiagramFactorSizeType;
    /**
     * Child factors
     */
    childFactors?: Array<ProjectDiagramGuideFactorInfo>;

    constructor(item?: Partial<ProjectDiagramGuideFactorInfo>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            clientResourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.clientResourceIdentifier : itemOrValue;

                    if (itemOrValue?.clientResourceIdentifier != null) {
                        if (itemOrValue.clientResourceIdentifier === '') {
                            itemOrValue.clientResourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.clientResourceIdentifier = parseInt(itemOrValue.clientResourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.clientResourceIdentifier = parseFloat(itemOrValue.clientResourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            factorType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorType : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            identifier: {
                
                minLength: 0,
                maxLength: 255,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 0, 255);

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            details: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.details : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            comments: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.comments : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            indicatorUnit: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.indicatorUnit : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            indicatorFutureStatus: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.indicatorFutureStatus : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            indicatorMeasurement: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.indicatorMeasurement : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            location: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.location : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            size: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.size : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            childFactors: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.childFactors : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProjectDiagramGuideFactorInfo.isValid,
        };
    }

    static isValid(obj: ProjectDiagramGuideFactorInfo): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectDiagramGuideFactorInfo.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.clientResourceIdentifier.isValid(item);
            result = result && validationObject.factorType.isValid(item);
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.details.isValid(item);
            result = result && validationObject.comments.isValid(item);
            result = result && validationObject.indicatorUnit.isValid(item);
            result = result && validationObject.indicatorFutureStatus.isValid(item);
            result = result && validationObject.indicatorMeasurement.isValid(item);
            result = result && validationObject.location.isValid(item);
            result = result && validationObject.size.isValid(item);
            result = result && validationObject.childFactors.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

