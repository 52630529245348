/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Goal } from './goal';
import { IndicatorFutureStatus } from './indicatorFutureStatus';
import { IndicatorMeasurement } from './indicatorMeasurement';
import { Indicator } from './indicator';


/**
 * Goal and related (created via SMART guide)
 */
import { ValidateUtils } from 'app/shared/utils';

export class SmartGoal { 
    goal?: Goal;
    indicator?: Indicator;
    indicatorFutureStatus?: IndicatorFutureStatus;
    indicatorMeasurement?: IndicatorMeasurement;

    constructor(item?: Partial<SmartGoal>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            goal: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.goal : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            indicator: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.indicator : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            indicatorFutureStatus: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.indicatorFutureStatus : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            indicatorMeasurement: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.indicatorMeasurement : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: SmartGoal.isValid,
        };
    }

    static isValid(obj: SmartGoal): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = SmartGoal.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.goal.isValid(item);
            result = result && validationObject.indicator.isValid(item);
            result = result && validationObject.indicatorFutureStatus.isValid(item);
            result = result && validationObject.indicatorMeasurement.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

