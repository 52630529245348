/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TaxonomySelectionTypeEnum } from './taxonomySelectionTypeEnum';
import { TaxonomyConfigurationTypeEnum } from './taxonomyConfigurationTypeEnum';
import { TaxonomyElement } from './taxonomyElement';


/**
 * Taxonomy version configured for Factor
 */
import { ValidateUtils } from 'app/shared/utils';

export class FactorTaxonomy { 
    /**
     * Unique identifier for Taxonomy Version
     */
    readonly taxonomyVersionResourceIdentifier?: string;
    /**
     * Taxonomy Version
     */
    readonly taxonomyVersion?: string;
    /**
     * Taxonomy Version label
     */
    readonly label?: string;
    /**
     * Taxonomy Version description
     */
    readonly description?: string;
    selectionType?: TaxonomySelectionTypeEnum;
    /**
     * Flag indicating whether Taxonomy supports multi-select or not
     */
    readonly multiSelect?: boolean;
    /**
     * Flag indicating whether Taxonomy is a standard classification or not
     */
    readonly isFrameworkTaxonomy?: boolean;
    configurationType?: TaxonomyConfigurationTypeEnum | null;
    /**
     * Sequence no for accounting classification
     */
    readonly accountingSequenceNo?: number | null;
    /**
     * Taxonomy elements
     */
    taxonomyElements?: Array<TaxonomyElement>;

    constructor(item?: Partial<FactorTaxonomy>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            taxonomyVersionResourceIdentifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.taxonomyVersionResourceIdentifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            taxonomyVersion: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.taxonomyVersion : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            label: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.label : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            description: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.description : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            selectionType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.selectionType : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            multiSelect: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.multiSelect : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isFrameworkTaxonomy: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isFrameworkTaxonomy : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            configurationType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.configurationType : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            accountingSequenceNo: {
                type: 'int32',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.accountingSequenceNo : itemOrValue;

                    if (itemOrValue?.accountingSequenceNo != null) {
                        if (itemOrValue.accountingSequenceNo === '') {
                            itemOrValue.accountingSequenceNo = undefined;
                        } else if (['int32', 'int64'].indexOf('int32') >= 0) {
                            itemOrValue.accountingSequenceNo = parseInt(itemOrValue.accountingSequenceNo.toString(), 10);
                        } else if (['float'].indexOf('int32') >= 0) {
                            itemOrValue.accountingSequenceNo = parseFloat(itemOrValue.accountingSequenceNo.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'int32');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            taxonomyElements: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.taxonomyElements : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: FactorTaxonomy.isValid,
        };
    }

    static isValid(obj: FactorTaxonomy): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = FactorTaxonomy.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.taxonomyVersionResourceIdentifier.isValid(item);
            result = result && validationObject.taxonomyVersion.isValid(item);
            result = result && validationObject.label.isValid(item);
            result = result && validationObject.description.isValid(item);
            result = result && validationObject.selectionType.isValid(item);
            result = result && validationObject.multiSelect.isValid(item);
            result = result && validationObject.isFrameworkTaxonomy.isValid(item);
            result = result && validationObject.configurationType.isValid(item);
            result = result && validationObject.accountingSequenceNo.isValid(item);
            result = result && validationObject.taxonomyElements.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

